body{
 background-color:#f6f7f8;
}

.mainMenu{
  margin-bottom:15px;
}

/*
  снятие стилей из реакта Link чтобы не накладывалось 
  на бутстраповские 
*/
a {
  color: inherit;
  text-decoration: none;
}

.mainContent{
  margin-top:70px;
}

.mainFooter{
  background-color: gray;
  color:white;
  min-height:200px; 
  padding-top:10px;
  border-top:1px solid white; 
}

/*
  так работает настройка для inWindow контента 
  мы просто меняем класс окна - исходя из того 
  на сколько оно считается большим или нет 
  так смена одного класса окна сверху изменит 
  все лейауты вложенных элементов 
*/
.inSmallWindow{
  color:red !important;

  .col-md-6{
    width:100% !important;
  }
}

.App {
  /*background-color:white;*/
  min-height:80vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  */
}

.App-link {
  /*color: #61dafb;*/
}


.navbar-light{
  background-color: white !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
